const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export const cookieApi = () =>
    fetch(`${baseUrl}`, {
        method: 'POST',
        headers: {
            'x-api-key': apiKey,
        },
        body: JSON.stringify({'init': true})
        //credentials: 'include',
    });

export const authApi = (device_id, auth) =>
    fetch(`${baseUrl}`, {
        method: 'POST',
        headers: {
            'x-api-key': apiKey,
            'Authorization': auth
        },
        //credentials: 'include'
    });

export const fetchApi = (device_id, auth) =>
    fetch(`${baseUrl}`, {
        method: 'POST',
        headers: {
            'x-api-key': apiKey,
            'Authorization': auth
        },
        //credentials: 'include',
        body: JSON.stringify({'device_id': device_id})
    });

export const uploadApi = (data) =>
    fetch(`${baseUrl}/upload`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey
        },
        body: data
    });

export const gets3Url = (id, name) =>
    fetch(`${baseUrl}/presigned`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey
        },
        body: JSON.stringify({
            "device_id": id,
            "installer_name": name
        })
    });
