import React, { Component } from 'react';
import './App.scss';
import DeviceHandler from "./DeviceHandler";
import pic from "./img/uid_location.jpg";
import logo from "./img/logo-black.png";
import { authApi, cookieApi } from "./util/api";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            user: '',
            pw: '',
            btoa: '',
        };
    }

    componentDidMount() {
        this.checkCookie();
    }

    handleSubmit(event) {
        const { user, pw } = this.state;
        const btoa =  window.btoa(`Basic ${user}:${pw}`);
        this.setState({btoa: btoa});
        event.preventDefault();
        authApi("001098",btoa)
            .then(this.handleErrorsAuth)
            .then(response => this.handleResult(response))
            .catch(error => console.log(error));
    }

    handleResult(res) {
        this.setState({loggedIn: true});
    }

    handleErrorsAuth(response) {
        if (!response.ok) {
            //alert("Virheelliset käyttöoikeustiedot");
            alert("Invalid credentials");
            this.setState({pw: ""});
            throw new Error(response.status);
        }
        return response;
    }

    handleErrors(response) {
        if (!response.ok) {
            throw new Error(response.status);
        }
        return response;
    }

    checkCookie() {
        cookieApi()
            .then(this.handleErrors)
            .then(response => this.handleResult(response))
            .catch(error => console.log(error));
    }

    closeImage() {
            let boxImage = document.getElementById("uid");
            let container = document.getElementsByClassName("container")[0];

            boxImage.setAttribute("style", "display: none");
            container.setAttribute("style", "filter: none");
        }

    render() {
        const { loggedIn, user, pw, btoa } = this.state;
        return (
            <div className="App">
                {loggedIn ? [
                    <DeviceHandler key="1" btoa={btoa} user={user}/>,
                    <div key="2" id="uid">
                        <span id="closeimg" onClick={this.closeImage}>X</span>
                        <img id="uid-img" src={pic} alt="uid location on box"/>
                    </div>]
                    :
                    <div className="login">
                        <form onSubmit={e => this.handleSubmit(e)}>
                            <img id="gridio-logo" src={logo} alt="gridio logo"/>
                            <input
                                type="text"
                                autoFocus
                                value={user}
                                placeholder="Username"
                                onChange={e => this.setState({user: e.target.value})}
                            />
                            <input
                                type="password"
                                value={pw}
                                placeholder="Password"
                                onChange={e => this.setState({pw: e.target.value})}
                            />
                            <button type="submit">
                                Log in
                            </button>
                        </form>
                    </div>
                }
            </div>
        )
    }
}
