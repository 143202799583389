import React, { Component } from "react"
import Phase from "./Phase";
import { gets3Url, uploadApi } from "../util/api";
import { Line } from 'rc-progress';

const options = [
    [
        'Water heater', 'Floor heating', 'Heating', 'Other (details below)', 'No device',
        'Same device as L2', 'Same device as L3'
    ],
    [
        'Water heater', 'Floor heating', 'Heating', 'Other (details below)', 'No device',
        'Same device as L1', 'Same device as L3'
    ],
    [
        'Water heater', 'Floor heating', 'Heating', 'Other (details below)', 'No device',
        'Same device as L1', 'Same device as L2'
    ]
];
const information_placeholder = "Installation details (e.g., special settings)";

class DataPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attached_file: "",
            attached_text: "",
            submitButtonDisabled: false,
            presigned: null,
            loadedPercent: 0,
            loading: false,
        };
        this.updatePhases = this.updatePhases.bind(this);
    }

    componentDidMount() {
        this.upload_btn = document.getElementById("upload-btn");
        this.setState({device_id: this.props.data.measurement.device_id});
    }

    uploadImage(json) {
        const formData = new FormData();

        Object.keys(this.state.presigned.fields).forEach(key => {
            formData.append(key, this.state.presigned.fields[key]);
        });
        formData.append("file", this.state.attached_file);

        let request = new XMLHttpRequest();
        request.open('POST', this.state.presigned.url);

        let sb = document.getElementById("submit-data");

        this.setState({
            loading: true,
        })

        request.upload.addEventListener('progress', function(e) {
            let percent_completed = (e.loaded / e.total)*100;

            this.setState({
                loadedPercent: percent_completed.toFixed(1)
            })

            if (percent_completed === 100) {

                uploadApi(JSON.stringify(json))
                    .then(this.handleErrors)
                    .then(response => this.handleResult(response))
                    .catch(error => console.log(error));
            }

        }.bind(this))

        request.send(formData)
    }

    togglePhaseHighlight(l) {
        const phase = document.getElementById(l).getElementsByClassName("Dropdown-control")[0];
        phase.setAttribute("style", "border: 2px solid crimson");
    }

    checkPhases() {
        const { L1, L2, L3 } = this.state;

        if (L1.deviceType === '') {
            this.togglePhaseHighlight("L1");
        }

        if (L2.deviceType === '') {
            this.togglePhaseHighlight("L2", true);
        }

        if (L3.deviceType === '') {
            this.togglePhaseHighlight("L3", true);
        }

        if (L1.deviceType === '' || L2.deviceType === '' || L3.deviceType === '') {
            alert("Syötä laitteen tiedot ennen kuin painat “Lähetä“.");
            return false;
        }
        return true;
    }

    submitData() {
        const { device_id } = this.props.data.measurement;
        const { presigned, L1, L2, L3, attached_text } = this.state;

        if (!this.checkPhases()) return;

        const cur_date = new Date().toISOString();
        const outJson = {
            datetime: cur_date,
            device_id: device_id,
            phases: {
                L1,
                L2,
                L3
            },
            img_url: (presigned !== null) ? presigned.url + presigned.fields.key : "",
            add_info: attached_text,
            installer: this.props.user,
            uploadMsg: this.state.uploadMsg,

        };

        if (presigned !== null) {
            this.uploadImage(outJson)
        }
        else {
            uploadApi(JSON.stringify(outJson))
                .then(this.handleErrors)
                .then(response => this.handleResult(response))
                .catch(error => console.log(error));
        }
    }

    handleErrors(response) {
        if (!response.ok) {
            throw new Error(response.status);
        }
        return response;
    }

    handleResult(res) {
        this.setState({
            loading: false
        })
        //alert("Asennus onnistui!")
        alert("Installation successful!")
    }

    handleFileInput(event) {
        this.filename = event.target.files[0].name;
        this.file = event.target.files[0];
        this.upload_btn.innerHTML = this.filename;

        this.setState({submitButtonDisabled: true});

        gets3Url(this.state.device_id, this.props.user)
            .then(this.handleErrors)
            .then(res => res.json())
            .then(res => this.setState({
                attached_file: this.file,
                presigned: res,
                submitButtonDisabled: false})
        );
    }

    handleTextInput(event) {
        this.setState({attached_text: event.target.value});
    }

    updatePhases(phase, data) {
        this.setState({
            [phase]: data
        })
    }

    OnlineStatus() {
        const { measurement, signal, address, status } = this.props.data;
        const phases = [];
        for (const [i, v] of measurement.data.entries()) {
            phases.push(<Phase updatePhases={this.updatePhases} options={options[i]} id_key={i+1} key={i} data={v}/>)
        }

        return (
            <div className="status-body">
                <div className="status-panel">
                    <p className="status-row">
                        <span className="key">Installation</span>
                        <span id="online" className="value">Success</span>
                    </p>
                    <p className="status-row">
                        <span className="key">Network</span>
                        <span id="status" className="value">{(status === 201) ? 'connected' : 'updating'}</span>
                    </p>
                    <p className="status-row">
                        <span className="key">Signal</span>
                        <span id="signal" className="value">{signal.data.value} / 31</span>
                    </p>
                    { (address.length > 0) ?
                    <p className="status-row">
                        <span className="key">Address</span>
                        <span id="address" className="value">{address}</span>
                    </p> : null }
                </div>
                <div className="status-body-stage">Step 1/3: enter the device information</div>
                <div className="phase-wrapper">
                    {phases}
                    <div className="status-body-stage asterisk">*Select only if the day/night relay is not installed.</div>
                </div>
                <div className="status-body-stage">Step 2/3: add comments (if any)</div>
                <div className="information">
                    <textarea id="additional-info" className="information-box" placeholder={information_placeholder} onChange={e => this.handleTextInput(e)} />
                    <div className="status-body-stage">Step 3/3: Add a photo (optional)</div>
                    { this.state.loading ?
                        <Line
                            percent={ this.state.loadedPercent }
                            strokeWidth="3" trailWidth="3" strokeLinecap="square" /> : null }
                    <div className="information-attachment">
                        <button id="upload-btn" className="btn">Add an image of the installation</button>
                        <input id="attach-file" type="file" accept="image/*" onChange={e => this.handleFileInput(e)}/>
                        <button className="btn" disabled={this.state.submitButtonDisabled} id="submit-data" onClick={() => this.submitData()}>Submit</button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return this.OnlineStatus()
    }
}

export default DataPanel;