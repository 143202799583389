import React, {Component} from "react"
import Dropdown from 'react-dropdown'

const defaultOption = 'Ei laitetta';

const arrowClosed = (
    <span className="arrow-closed"/>
);

const arrowOpen = (
    <span className="arrow-open"/>
);

class Phase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            selected: '',
            nightTariff: false,
        }
    }

    componentDidMount() {
        const {id_key, data} = this.props;
        const phaseId = 'L' + id_key;
        if (!parseFloat(data.voltage) > 0)
            this.setState({selected: defaultOption});
        this.setState({id: phaseId})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state !== prevState) {
            this.props.updatePhases(this.state.id, {
                deviceType: this.state.selected,
                nightTariff: this.state.nightTariff
            });
        }
    }

    handleRadio() {
        this.setState(state => ({
            nightTariff: !state.nightTariff
        }))
    }

    handleDeviceSelection(s) {
        document.getElementById(
            this.state.id).getElementsByClassName(
                "Dropdown-control")[0].setAttribute(
                    "style", "border: 1px solid #bbbbbb");
        this.setState({selected: s})
    }

    render() {
        const {id_key, data, options} = this.props;
        const voltage = parseFloat(data.voltage).toFixed(1);
        const current = parseFloat(data.current).toFixed(1);
        const blinkClass = (voltage > 0) ? 'phase-blink active' : 'phase-blink';
        const disabled = !(voltage > 0);
        const nt_disabled = disabled || this.state.selected.value === defaultOption;

        return (
            <div className="phase">
                <span className="phase-id">L</span><span className="phase-id-num">{id_key}</span>
                <div className="phase-info" id={this.state.id}>
                    <div className="phase-metrics">
                        <span className={blinkClass}/>
                        <span>Voltage:</span><span className="phase-voltage">{voltage}V</span>
                        <span>Current:</span><span className="phase-current">{current}A</span>
                    </div>
                    <Dropdown className="phase-dropdown"
                          disabled={disabled}
                          arrowClosed={arrowClosed}
                          arrowOpen={arrowOpen}
                          options={options}
                          value={(disabled) ? defaultOption : this.state.selected}
                          placeholder="Valitse laite.."
                          onChange={s => this.handleDeviceSelection(s.value)}
                    />
                    <label className="phase-check">
                        <input type="checkbox" disabled={nt_disabled} checked={this.state.nightTariff}
                               onChange={e => this.handleRadio(e)}/>
                        Night electricity via Gridio controller*
                    </label>
                </div>
            </div>
        )
    }
}

export default Phase;