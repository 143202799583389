import React, {Component} from "react";
import { fetchApi } from "./util/api";
import DataPanel from "./components/DataPanel";
import UidInput from "./components/UidInput";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import logo from "./img/logo-notext.png";

class DeviceHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            pw: '',
            uid: '',
            isLoaded: false,
            data: {},
            showPicture: false,
            error: false,
        };
    }

    showDivs(bool) {
        if (bool) {
            this.headDiv.setAttribute("style", "display: block");
        }
        else {
            this.headDiv.setAttribute("style", "display: none");
        }
    }

    componentDidMount() {
        this.headDiv = document.getElementById("head");
        this.setState({user: this.props.user})
    }

    getUid(uid) {
        this.setState({
            uid: uid,
        });
        if (uid.length === 4 && uid < 10000 && uid >= 0) {
            this.intervalId = setInterval(() => this.fetchData(uid), 2000);
            this.fetchData(uid)
        }
        else {
            clearInterval(this.intervalId);
            this.setState({isLoaded: false, error: false});
            this.showDivs(true);
        }
    }

    fetchData(uid) {
        fetchApi(uid, this.props.btoa)
            .then(this.handleErrors)
            .then(res => this.handleResult(res))
            .catch(err => {
                clearInterval(this.intervalId);
                this.showDivs(true);
                this.setState({error: true});
            })
    }

    handleErrors(response) {
        if (!response.ok) {
            clearInterval(this.intervalId);
            this.showDivs(true);
            this.setState({error: true});
        }
        return response.json();
    }

    handleResult(response) {
        this.showDivs(false);
        this.setState({
            isLoaded: true,
            data: response,
            error: false
        })
    }

    toggleVisible() {
        let container = document.getElementsByClassName("container")[0];
        let boxImage = document.getElementById("uid");

        if (getComputedStyle(container).getPropertyValue("filter") === "blur(10px)") {
            container.setAttribute("style", "filter: none");
            boxImage.setAttribute("style", "display: none");
        }
        else {
            container.setAttribute("style", "filter: blur(10px)");
            boxImage.setAttribute("style", "display: block");
        }
    }

    ErrorStatus() {
        return (
            <div className="connection-status">
                <span className="connection-status-fail">The controller is unable to connect</span>
                <ol>
                    <li>
                        Insert the ID again
                    </li>
                    <li>
                        Check the antenna
                    </li>
                    <li>
                        Make sure that L1 is connected
                    </li>
                    <li>
                        To reset the controller, turn the fuses on all connected devices off and on again.
                    </li>
                    <li>
                        Wait 5 minutes - if the connection still fails - contact your energy company.
                    </li>
                </ol>
            </div>
        )
    }

    render() {
        const { isLoaded, data, error } = this.state;
        const { user } = this.props;
        return (
            <div className="container">
                <div id="head">
                    <img id="gridio-logo-front" src={logo} alt="gridio logo" />
                    <div id="title-block">
                        <span id="title-block-name">GridIO</span>
                        <span id="title-block-tool">Installer tool</span>
                    </div>
                    <div className="prompt">
                        <h3>Insert ID ↓ </h3>
                        <span id="questionmark" onClick={() => this.toggleVisible()}>?</span>
                    </div>
                </div>
                <UidInput getUid={(uid) => this.getUid(uid)} />
                { (isLoaded) ?
                    <ReactCSSTransitionGroup
                        transitionName="example"
                        transitionAppear={true}
                        transitionAppearTimeout={500}
                        transitionEnter={false}
                        transitionLeave={false}>
                        <DataPanel error={error} user={user} data={data}/>
                    </ReactCSSTransitionGroup>
                    : null }
                { (error) ?
                    this.ErrorStatus()
                    : null }
            </div>
        );
    }
}

export default DeviceHandler;
