import React from 'react';

class UidInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uid:[],
            value:''
        };
        this.uidTextInput = [];

    }

    componentDidMount() {
        this.uidTextInput[0].focus();
    }

    renderInputs() {
        const inputs = Array(4).fill(0);
        const txt = inputs.map(
            (i, j) =>
                <div key={j} className="txtInput">
                    <input
                        type="text"
                        className="inputRadius"
                        maxLength="1"
                        inputMode="numeric"
                        onChange={e => this.focusNext(j, e)}
                        onKeyDown={e => this.focusPrevious(e, j)}
                        ref={ref => this.uidTextInput[j] = ref}
                    />
                </div>
        );
        return txt;
    }

    SetUid(index, value) {
        const uid = this.state.uid;
        uid[index] = value;
        this.setState({ uid });
        this.props.getUid(uid.join(''));
    }

    focusPrevious(event, index) {
        if (event.key === 'Backspace') {
            if (this.uidTextInput[index] !== '') {
                this.uidTextInput[index].value = '';
            }
            if (index !== 0) {
                this.uidTextInput[index - 1].focus();
                event.preventDefault();
            }
            else if (index !== 0 && this.uidTextInput[index].value === '' && this.uidTextInput[index-1].value !== '' ) {
                this.uidTextInput[index - 1].value = '';
                this.uidTextInput[index - 1].focus();
            }
            this.SetUid(index, event.target.value);
        }

        else if (index !== 3 && this.uidTextInput[index].value !== '') {
            this.uidTextInput[index + 1].focus();
            this.SetUid(index, event.target.value);
        }
    }

    focusNext(index, event) {
        if (event.target.value !== '')
            if (index < this.uidTextInput.length - 1 && event) {
                this.uidTextInput[index + 1].focus();
            }
            if (index === 3) {
                this.uidTextInput[index].blur();
            }
            this.SetUid(index, event.target.value);
    }


    render() {
        return (
            <div className="inputContainer">
                <span id="hashtag">#</span>
                {this.renderInputs()}
            </div>
        );
    }
}

export default UidInput;